import type{ AuthProvider, User } from './contracts/AuthProvider';
import type { LogtoDriverConfig } from './config';
import LogtoClient from '@logto/browser';


export class LogtoAuthProvider implements AuthProvider {

  #logtoClient: LogtoClient;
  #logtoConfig: LogtoDriverConfig;

  constructor(config: LogtoDriverConfig) {
    this.#logtoClient =  new LogtoClient(
      {
        endpoint: config.endpoint,
        appId: config.appId,
        resources: config.resources,
        scopes: config.scopes
      }
    );

    this.#logtoConfig = config;
  }

  async signIn(): Promise<void> {
    return await this.#logtoClient.signIn(this.#logtoConfig.signInRedirectUri);
  }

  async signOut(): Promise<void> {
    return await this.#logtoClient.signOut(this.#logtoConfig.signOutRedirectUri)
  }

  async user(): Promise<User> {
    const claims = await this.#logtoClient.getIdTokenClaims();

    return {
      name: claims.name,
      email: claims.email,
      username: claims.username,
      profile_picture_url: claims.picture,
      email_verified: claims.email_verified,
      phone_number: claims.phone_number,
      phone_number_verified: claims.phone_number_verified
    }
  }

  async accessToken(): Promise<string> {
    return await this.#logtoClient.getAccessToken(this.#logtoConfig.resources[0]);
  }

  async isAuthenticated() {
    return await this.#logtoClient.isAuthenticated();
  }

  async clearAll(): Promise<void> {
    return await this.#logtoClient.clearAllTokens();
  }

  async handleSignInRedirectCallback(redirectCallbackUri: string): Promise<void> {
    return await this.#logtoClient.handleSignInCallback(redirectCallbackUri);
  }
}