import type { AuthConfig, LogtoDriverConfig } from "./config";
import type { AuthProvider, User } from "./contracts/AuthProvider";
import { LogtoAuthProvider } from "./LogtoAuthProvider";
import { NoneAuthProvider } from "./NoneAuthProvider";

export class AuthManager implements AuthProvider {

    protected config: AuthConfig;

    protected drivers: Map<string, AuthProvider> = new Map();

    public constructor(config: AuthConfig) {
        this.config = config;
    }

    public getDefaultDriver() {
        return this.config.default;
    }

    public driver(driverName?: string)
    {
        const driver = driverName?? this.getDefaultDriver();

        // If the given driver has not been created before, we will create the instances
        // here and cache it so we can return it next time very quickly. If there is
        // already a driver created by this name, we'll just return that instance.
        if (! this.drivers.has(driver)) {
            const createdDriver = this.createDriver(driver)
            this.drivers.set(driver, createdDriver);
            return createdDriver;
        }

        return this.drivers.get(driver)!;
    }

    protected createDriver(driver: string)
    {
        switch(driver) {
            case 'none':
                return this.createNoneDriver();
            case 'logto':
                return this.createLogtoDriver();
            default:
                throw new Error(`Driver ${driver} not supported`);
        }
    }

    protected createNoneDriver(): AuthProvider {
        return new NoneAuthProvider();
    }

    protected createLogtoDriver(): AuthProvider {
        const logtoConfig = this.config.drivers.get('logto') as LogtoDriverConfig
        return new LogtoAuthProvider(logtoConfig);
    }

    async signIn(): Promise<void> {
        return await this.driver().signIn();
    }

    async signOut(): Promise<void> {
        return await this.driver().signOut()
    }
    
    async user(): Promise<User> {
        return await this.driver().user()
    }

    async accessToken(): Promise<string> {
        return await this.driver().accessToken()
    }

    async isAuthenticated(): Promise<boolean> {
        return await this.driver().isAuthenticated()
    }

    async clearAll(): Promise<void> {
        return await this.driver().clearAll();
    }

    async handleSignInRedirectCallback(redirectCallbackUri: string): Promise<void> {
        return await this.driver().handleSignInRedirectCallback(redirectCallbackUri);
    }

}