import type { AuthProvider, User } from "./contracts/AuthProvider";

const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));

export class NoneAuthProvider implements AuthProvider {

    isAuth = true;

    async signIn(): Promise<void> {
        await sleep(1200);
        this.isAuth = true;
        return;
    }

    async signOut(): Promise<void> {
        await sleep(1200);
        this.isAuth = false;
        return;
    }

    async user(): Promise<User> {
        await sleep(800);
        return {
            email: 'test@osos.edu.ly',
            name: 'Mr. Test',
            username: 'MrTest',
        }
    }

    async accessToken(): Promise<string> {
        const chanceOfFailure = parseFloat(Math.random().toFixed(2)) >= 0.5;
        if(chanceOfFailure) throw new Error('not_authenticated');
        return '';   
    }

    async isAuthenticated(): Promise<boolean> {
        await sleep(200)
        return this.isAuth;
    }

    async clearAll(): Promise<void> {
        await sleep(100);
        this.isAuth = false;
        return;
    }

    async handleSignInRedirectCallback(redirectCallbackUri: string): Promise<void> {
        await sleep(300);
        this.isAuth = true;
        return;
    }
}