import { UserScope } from "@logto/browser";
import { AuthManager } from "../src/AuthManager";
import { useRuntimeConfig } from "#app/nuxt";

export function useAuthManager() {
    const config = useRuntimeConfig()
    const authManager = new AuthManager({
        default: config.public.authProvider as 'logto' || 'none',
        drivers: new Map([
            [
                config.public.authProvider as 'logto' || 'none',
                {
                    endpoint: config.public.logtoEndpoint,
                    appId: config.public.logtoAppId,
                    scopes: [
                      UserScope.Email
                    ],
                    resources: config.public.logtoResources,
                    signInRedirectUri: config.public.logtoSignInRedirectUri,
                    signOutRedirectUri: config.public.logtoSignOutRedirectUri
                }
            ],
        ])
    });

    return {
        authManager
    }
}